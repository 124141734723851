import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CreateShareParameters, ShareFile } from '@celum/sharing';
import { ContentItem, ContentItemStatus } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { File, FileType, RenditionTypes } from '@celum/work/app/core/model/entities/file/file.model';
import { selectRenditionsForFileVersions } from '@celum/work/app/core/model/entities/file-version/file-version.selectors';
import { FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { Rendition } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { selectCurrentWorkroom } from '@celum/work/app/pages/workroom/store/workroom-wrapper.selectors';
import { DownloadUrlUtil } from '@celum/work/app/shared/util';

export enum ShareSelectionType {
  FILES = 'Files',
  FOLDERS = 'Folders',
  FILES_AND_FOLDERS = 'FilesAndFolders'
}

export interface WorkroomShareCreateDTO extends CreateShareParameters {
  libraryId: string;
  sharedFiles: any[];
}

export interface WorkroomShare {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  constructor(
    @Inject(DOCUMENT) public document: Document,
    public store: Store<any>,
    private http: HttpClient
  ) {}

  public getSelectionType(selection: ContentItem[]): ShareSelectionType {
    if (selection.every(item => item.entityType.id === FileType.TYPE_KEY)) {
      return ShareSelectionType.FILES;
    }
    if (selection.every(item => item.entityType.id === FolderType.TYPE_KEY)) {
      return ShareSelectionType.FOLDERS;
    }
    return ShareSelectionType.FILES_AND_FOLDERS;
  }

  public isShareable(selection: ContentItem[]): boolean {
    // it's sufficient to check the first element of the selection -> if it's not deleted,
    // the whole selection can't be in the trashbin and is therefore shareable
    return selection[0]?.status === ContentItemStatus.NOT_DELETED;
  }

  public getTranslationKey(selectedItemsType: ShareSelectionType, selection: ContentItem[]): string {
    switch (selectedItemsType) {
      case ShareSelectionType.FILES:
        return selection.length > 1 ? 'WORK_SHARING.FILES.PLURAL' : 'WORK_SHARING.FILES.SINGULAR';
      case ShareSelectionType.FOLDERS:
        return selection.length > 1 ? 'WORK_SHARING.FOLDERS.PLURAL' : 'WORK_SHARING.FOLDERS.SINGULAR';
      case ShareSelectionType.FILES_AND_FOLDERS:
        return 'WORK_SHARING.ITEMS';
      default:
        return 'WORK_SHARING.FILES.SINGULAR';
    }
  }

  public getFilesWithPreviewUrl(selectedFilesToShare: File[]): Observable<ShareFile[]> {
    return this.getSmallRenditionsFromStore(selectedFilesToShare).pipe(
      map(smallRenditions => {
        const smallRenditionsDictionary = smallRenditions.reduce(
          (acc, rendition) => ({ ...acc, [rendition.fileVersionId]: rendition.downloadUrl }),
          {}
        );
        return selectedFilesToShare.map(item => {
          return {
            id: item.id.toString(),
            fileName: item.name,
            previewUrl: smallRenditionsDictionary[item.activeVersionId]
          };
        });
      })
    );
  }

  public triggerCreateShare(parameters: WorkroomShareCreateDTO): Observable<WorkroomShare> {
    return this.http.post<WorkroomShare>(
      `${(this.document.defaultView.window as any).Celum.properties.sharingHttpBaseAddress}/share`,
      parameters
    );
  }

  private getSmallRenditionsFromStore(selectedFilesToShare: any[]): Observable<Rendition[]> {
    return this.store
      .select(selectRenditionsForFileVersions(selectedFilesToShare.map(file => file.activeVersionId)))
      .pipe(
        take(1),
        concatLatestFrom(() => this.store.select(selectCurrentWorkroom)),
        map(([renditions, currentWorkroom]) => {
          return renditions
            .map(rendition => rendition.filter(ren => ren.type === RenditionTypes.SMALL))
            .flat()
            .map(rendition => ({
              ...rendition,
              downloadUrl: DownloadUrlUtil.resolveDownloadUrl(rendition.downloadUrl, currentWorkroom)
            }));
        })
      );
  }
}
