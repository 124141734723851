import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { CelumDialogOpener } from '@celum/internal-components';
import { UiStateTenantSelected } from '@celum/work/app/core/ui-state/ui-state.actions';

import { TenantService } from './tenant.service';
import {
  ImportToWorkroomsDialogComponent,
  ImportToWorkroomsDialogConfiguration
} from '../../pages/dashboard/components/workroom-import-from-ch/import-to-workrooms-dialog/import-to-workrooms-dialog.component';

@Injectable({ providedIn: 'root' })
export class PortalImportGuard implements CanActivate {
  public static readonly PORTAL_ID_PARAM = 'portalId';
  private isPortalApprovalEnabled: boolean;

  constructor(
    private router: Router,
    private tenantService: TenantService,
    private store: Store<any>,
    private dialogOpener: CelumDialogOpener,
    private authService: AuthService
  ) {
    this.isPortalApprovalEnabled = (window as any).Celum.properties.features.portalApproval;
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const portalId = route.queryParamMap.get(PortalImportGuard.PORTAL_ID_PARAM);

    if (!portalId || !this.isPortalApprovalEnabled) {
      return of(true);
    }
    // Ensure the user is fully authenticated (MSALs interactive login flow is complete)
    // before opening the "Add to Workroom" dialog. While the AuthGuard initiates sign-in,
    // it doesn't wait for MSAL to finish its redirect flow. On first navigation
    // (e.g., coming from Experience with a portalId in the URL), this can cause
    // "interaction_in_progress" errors, preventing the dialog from opening.
    // Waiting for `isAuthenticated$` to emit `true` ensures the user is authenticated
    // and the dialog opens as expected.
    return this.authService.isAuthenticated$.pipe(
      first(isAuthenticated => isAuthenticated),
      switchMap(() => {
        const tenant = this.tenantService.getTenantFromUrl() || this.tenantService.getStoredTenant();
        return this.handlePortalId(tenant, portalId);
      })
    );
  }

  private handlePortalId(tenant: string, portalId: string): Observable<boolean | UrlTree> {
    return this.tenantService.getAvailableTenants().pipe(
      switchMap(availableTenants => {
        if (availableTenants.includes(tenant)) {
          this.store.dispatch(UiStateTenantSelected({ accountId: tenant }));
          this.dialogOpener.showDialog(
            ImportToWorkroomsDialogComponent.DIALOG_ID,
            ImportToWorkroomsDialogComponent,
            new ImportToWorkroomsDialogConfiguration(null, portalId),
            {
              disableClose: true
            }
          );
          return of(true);
        }
        return of(this.router.createUrlTree(['']));
      }),
      catchError(() => of(this.router.createUrlTree([''])))
    );
  }
}
